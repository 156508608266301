@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  background-color: #F3FBFF;
}
h1{
  font-family: "IBM Plex Sans", system-ui;
  font-weight: 600;
  font-style: normal;
  font-size: 23px;
}
h2{
  font-family: "IBM Plex Sans", system-ui;
  font-weight: 900;
}
.navText{
  font-family: "IBM Plex Sans", system-ui;
  font-weight: 900;
}
.desc{
  font-family: "IBM Plex Sans", system-ui;
  font-weight: 300;
}
.semiBold{
  font-family: "IBM Plex Sans", system-ui;
  font-weight: 500;
}
.normal{
  font-family: "IBM Plex Sans", system-ui;
  font-weight: 400;
}
.Bold{
  font-family: "IBM Plex Sans", system-ui;
  font-weight: bold;
}